<template>
<v-container fluid fill-height class="pa-0">
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `px-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0 break-word justify-space-between">
            Контент :: get-content-goods
        </v-card-title>
        <v-card-text class="px-0">
            <p>Дозволяє вивантажувати контент по товарам обраних груп.</p>
        </v-card-text>
        <v-card-text class="pa-4 pa-md-6 pb-3 blue lighten-5">
            <p><b>Метод:</b> <b class="primary--text">get-content-goods</b>, моделі <b class="primary--text">catalog</b></p>
            <p><b>URL:</b> POST {{url}}</p>
            <p><b>Формат:</b> <b class="primary--text">JSON</b></p>
            <p><b>Доступність:</b> За отриманим Токеном авторизації <b class="primary--text"><a @click="$router.push('/api-services/get-auth-token')">authToken</a></b></p>
        </v-card-text>
        <v-card-title class="px-0 break-word mt-4">
            Параметри запиту
        </v-card-title>
        <v-card-text class="px-0">
            <v-row no-gutters>
                <v-col cols="12" md="7" class="py-0">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left" width="25%">
                                        Параметр запиту
                                    </th>
                                    <th class="text-left" width="20%">
                                        Тип даних
                                    </th>
                                    <th class="text-left" width="55%">
                                        Опис
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in params" :key="item.name">
                                    <td :class="item.req ? `primary--text font-weight-bold` : ``">{{ item.name }}
                                        <v-icon v-if="item.req" color="primary" class="mb-3">*</v-icon>
                                    </td>
                                    <td>{{ item.type }}</td>
                                    <td v-html="item.descr"></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-title class="px-0 break-word">
            Заголовки HTTP
        </v-card-title>
        <v-card-text class="px-0">
            <v-row no-gutters>
                <v-col cols="12" md="7" class="py-0 mb-6">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left" width="25%">
                                        Заголовок
                                    </th>
                                    <th class="text-left" width="75%">
                                        Значення
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in headers" :key="item.name">
                                    <td :class="item.req ? `font-weight-bold primary--text`:``">{{item.name}}
                                        <v-icon v-if="item.req" color="primary" class="mb-3">*</v-icon>
                                    </td>
                                    <td v-html="item.value"></td>
                                </tr>
                                <tr v-if="!headers.length">
                                    <td colspan="3" class="body-2 grey--text text-center font-weight-light">Заголовків немає</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-title class="px-0 break-word">
            Перевірити роботу методу
        </v-card-title>
        <v-card-text class="px-0">
            <p>Для перевірки методу скористайтеся <b class="primary--text">Конструктором запиту</b>, та натисніть <b class="primary--text">Надіслати</b>.</p>
        </v-card-text>
        <h4>Параметри запиту</h4>
        <v-row no-gutters>
            <v-col cols="12" md="7" class="py-0">
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-text-field label="cats" v-model="cats"></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-text-field label="lang" v-model="lang"></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-text-field label="description_photo" v-model="description_photo"></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-btn color="primary" :disabled="loaded" @click="additionalColumnsDialog = !additionalColumnsDialog">
                            <v-icon>settings</v-icon> <span class="ml-5"> Конструктор запиту</span>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-10">
                    <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                        <v-textarea no-resize rows="9" outlined :value="requestTxt" label="HTTP Запит" type="text" readonly class="code"></v-textarea>
                        <v-btn icon @click="clickHandler(2)" title="Копіювати в буфер обміну" v-clipboard="() => requestTxt" v-clipboard:success="clipboardSuccessHandler">
                            <v-icon>{{copied[2] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                        <v-btn @click="postMethod()" color="primary" :loading="loaded">Надіслати</v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-10">
                    <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                        <v-textarea no-resize rows="9" outlined :value="response" label="HTTP Відповідь" readonly type="text" class="code"></v-textarea>
                        <v-btn icon @click="clickHandler(3)" title="Копіювати в буфер обміну" v-clipboard="() => response" v-clipboard:success="clipboardSuccessHandler">
                            <v-icon>{{copied[3] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                        </v-btn>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>

    <v-dialog v-model="additionalColumnsDialog" max-width="1200" width="600">
        <v-card>
            <v-system-bar window light>
                Конструктор запиту
                <v-spacer></v-spacer>
                <v-btn icon @click="additionalColumnsDialog = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-container>
                <!-- <v-row v-if="!(contactInfo.groups && contactInfo.groups.cladmin_content_export)">
                    <v-col>
                        <v-alert type="warning" outlined>
                            Доступ до вивантаження контенту обмежено.<br>Оберіть потрібну категорію та доступні групи та бренди будуть вказані у Переліку нижче.
                        </v-alert>
                    </v-col>
                </v-row> -->
                <v-row>
                    <v-col>
                        <v-card flat>
                            <h4>
                                Оберіть асортимент для виведення у cats:
                            </h4>
                            <v-card-text>
                                <v-treeview :open.sync="opened" @input="saveCat()" v-model="selection" :items="items" selectable dense return-object selected-color="primary"></v-treeview>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- <v-divider vertical class="my-6"></v-divider>
                    <v-col>
                        <v-card flat>
                            <h4>
                                Перелік обраних категорій та доступних брендів:
                            </h4>
                            <v-card-text>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th >
                                                    Род. категорія
                                                </th>
                                                <th class="text-left">
                                                    Категорія
                                                </th>
                                                <th class="text-left">
                                                    Бренд
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in selected" :key="index">
                                                <td>{{ item.parent }}</td>
                                                <td>{{ item.category }}</td>
                                                <td>{{ item.brand }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col> -->
                </v-row>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="additionalColumnsDialog = false" color="primary">Застосувати</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

import XMLfields from '../../XMLFields.vue'

export default {
    components: {
        XMLfields
    },
    directives: {},
    data: () => ({
        opened: [{
            id: 0
        }],
        url: process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/catalog/get-content-goods' : 'http://dev.yugcontract.ua:3033/api/catalog/get-content-goods',
        urlAuth: process.env.NODE_ENV === 'production' ? 'https://auth.yugcontract.ua/api/auth/get-auth-token' : 'http://dev.yugcontract.ua:3400/api/auth/get-auth-token',
        response: `{
    "method": "/api/catalog/get-content-goods",
    "status": "ok",
    "error": null,
    "content": []
}        
        `,
        copied: {},
        request: `POST {{url}} HTTP/1.0
Content-Type: application/json
Authorization: Bearer {{authToken}}

{
  "cats": [{{cats}}],
  "lang": "{{lang}}",
  "description_photo": {{description_photo}}
}
`,

        additionalColumnsDialog: false,
        fileBlobURL: '',
        closed: false,
        loaded: false,
        fileName: '',
        format: '',
        selection: [],
        items: [],
        show1: true,
        selected: [],
        lang: 'UA',
        description_photo: 0,
        params: [
            {
                name: 'lang',
                type: 'String',
                req: false,
                descr: 'Мова UA або RU.<br>За замовчуванням - UA.'
            },
            {
                name: 'cats',
                type: 'Array',
                req: false,
                descr: 'Перелік категорій товарів (при вивантаженні враховуються вказані категорії та всі їх підкатегорії, що доступні).<br>За замовчуванням - всі доступні категорії.<br><b>Значення:</b><br><ul><li>id категорій</li></ul>Отримати можна методом <a href="/api-services/get-categories" target="_blank">get-categories</a>.<br>Параметр надає можливість керувати обмеженням стандартного вивантаження всього каталогу.<br>Сформувати необхідний формат можна за допомогою <b>Конструктора запиту</b>'
            },
            {
                name: 'description_photo',
                type: 'Number',
                req: false,
                descr: '<b>Значення:</b><br><ul><li>0 - в описі товарів не буде посилань на фото (img)</li><li>1 - в описі товарів будуть на фото (img)</li></ul>Параметр надає можливість керувати обмеженням по виводу посилань на фотографії в тексті опису товарів. Зверніть увагу, що посилання на фото будуть відносні, тому потрібно обробити посилання. У разі пустого значення - посилань на фото в тексті опису товарів не буде'
            },
            
        ],
        headers: [{
                name: 'Content-Type',
                value: 'application/json',
                req: true
            },
            {
                name: 'Authorization',
                value: 'Bearer <b class="primary--text">authToken</b>',
                req: true
            },
        ],
        codeTxt: `
        `,
        cats: [],
    }),
    props: {},
    watch: {
        cats() {
            const cats = (typeof this.cats == 'string' ? this.cats.split(/\s*,\s*/).map(el => Number(el)).filter(el => typeof el == 'number' && el > 0) : this.cats) || []
            this.getContentCatalogTree(cats).then(data => this.selected = data)
        }
    },
    methods: {
        ...mapActions(['setSnackbar', 'touch', 'getCatalogTree', 'getSecretPare', 'postMethodRequest', 'getContentAvailableCats', 'getContentCatalogTree']),
        ...mapGetters(['catalogTree']),
        clickHandler(v) {
            this.$set(this.copied, v, true)
            setTimeout(() => {
                this.copied[v] = false
            }, 1000);
        },
        clipboardSuccessHandler() {
            this.setSnackbar(['success', 'Скопійовано в буфер обміну'])
        },
        create(type, format) {
            this.touch()
                .then(() => {
                    this.loaded = true
                    const user_id = this.contactInfo.userId
                })
        },
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },
        saveCols() {
            this.ext_cols = this.columns.filter(el => el.selected == true).map(el => el.col)
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        saveCat() {
            this.cats = this.selection.map(el => el.id)
        },
        setOpened() {
            this.opened.push()
        },
        postMethod() {
            const requestToken = this.requestToken
            this.loaded = true
            const cats = (typeof this.cats == 'string' ? this.cats.split(/\s*,\s*/).map(el => Number(el)).filter(el => typeof el == 'number' && el > 0) : this.cats) || []
            this.postMethodRequest({
                    method: '/catalog/get-content-goods',
                    params: {
                        cats,
                        lang: this.lang,
                        description_photo: this.description_photo
                    }
                })
                .then(res => {
                    this.setSnackbar(['success', '200 ОК'])
                    this.response = JSON.stringify(res, null, 4)
                    this.loaded = false
                })
                .catch(error => {
                    console.log(error)
                    this.setSnackbar(['error', error])
                    this.response = JSON.stringify(error.message, null, 4)
                    this.loaded = false
                })
        },
    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        codetext() {},
        requestTxt() {
            let code = this.request
            code = code.replace(/\{\{authToken\}\}/gi, this.getLocalStorage('authToken'))
            code = code.replace(/\{\{cats\}\}/gi, this.cats)
            code = code.replace(/\{\{lang\}\}/gi, this.lang)
            code = code.replace(/\{\{description_photo\}\}/gi, this.description_photo)
            code = code.replace(/\{\{url\}\}/gi, this.url)
            code = code.replace(/\{\{urlAuth\}\}/gi, this.urlAuth)
            return code
        },

    },
    created() {
        this.touch()
            .then(async () => {
                const acats = await this.getContentAvailableCats()
                this.getCatalogTree()
                    .then(data => {
                        this.items.push({
                            id: 0,
                            name: 'Виберіть категорію товарів:',
                            children: []
                        })
                        for (let idx in data.menuItems) {
                            const item = data.menuItems[idx]
                            this.items[0].children.push({
                                id: item.link,
                                name: item.text,
                                children: [],
                                disabled: acats[item.link] ? false : true
                            })
                            for (let i in data.menuSubItems) {
                                if (i == item.link) {
                                    const elements = data.menuSubItems[i]
                                    for (let id in elements) {
                                        const el = elements[id]
                                        this.items[0].children[idx].children.push({
                                            id: el.link,
                                            name: el.text,
                                            children: [],
                                            disabled: acats[el.link] ? false : true
                                        })
                                        if (data.menuSubItems[el.link]) {
                                            const elements = data.menuSubItems[el.link]
                                            for (let el of elements) {
                                                this.items[0].children[idx].children[id].children.push({
                                                    id: el.link,
                                                    name: el.text,
                                                    children: [],
                                                    disabled: acats[el.link] ? false : true
                                                })
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    })
            })
        const local = []
        for (let i in this.columns) {
            if (local.find(el => this.columns[i].col == el)) {
                this.columns[i].selected = true
            } else {
                this.columns[i].selected = false
            }
            this.$set(this.columns, i, this.columns[i])
        }
        this.$store.commit('setLoading', false)
    }
}
</script>

<style>
</style>
